.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.label {
  padding-right: 2em;
}

.header {
  width: 100%;
  border-bottom: solid 1px gray;
  padding-bottom: 1em;
  margin-bottom: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-link {
  font-size: large;
  font-weight: bold;
  padding: 1em;
  background-color: gray;
  color: black;
  flex: 0 0 15%;
}

.page-title {
  padding-left: 3em;
  font-size: x-large;
  float: right;
  flex: 1;
}

.page-subtitle {
  font-size: large;
}

.rbt-menu {
  z-index: 1;
  background-color: rgba(1000, 1000, 1000, 1);
  white-space: nowrap;
}

.body {
  margin-left: 3%;
  margin-right: 3%;
}

.inline-markdown p {
  display: inline;
}

.bordered {
  border-collapse: collapse;
}

.bordered td,
.bordered th {
  border: solid 1px black;
  padding-left: 5px;
  padding-right: 5px;
}
